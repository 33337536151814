body {
  margin: 0;
/*   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'JetBrains Mono', monospace,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000; /* Set the background color to black */
  color: #fff; /* Set the text color to white */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.building-supplies-table {
  width: 100%; /* Full width on mobile */
}

/* INDEX */
.index-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-half {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.right-half {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
}

h2 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
}

h4 {
  margin: 0;
}

.top-half,
.bottom-half {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
/*   margin-bottom: 10px; */
}
.index-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/* INDEX END */


@media (min-width: 768px) {
  .building-supplies-table {
    width: 33.33%; /* One-third of available width on desktop */
  }
}
